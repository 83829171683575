<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        One
        <stemble-latex content="$55\text{ g}$" />
        serving of a cereal provides
        <number-value :value="massA" unit="\text{mg}" />
        of sodium, which is
        <number-value :value="pctA" unit="\%" />
        of the recommended daily allowance. Determine the amount of sodium (in grams and moles) in
        the recommended daily allowance.
      </p>

      <calculation-input
        v-model="inputs.gramsA"
        class="mb-5"
        prepend-text="$\text{a) Mass}$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.molA"
        class="mb-5"
        prepend-text="$\text{b) Moles}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p>
        <small
          ><b>Helpful tip:</b> To enter a number in scientific notation, enter 1.0E5 or 1.0e5, for
          1.0 x 10<sup>5</sup> where the E# (or e#) denotes 10<sup>#</sup></small
        >
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';

export default {
  name: 'Question220',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        gramsA: null,
        molA: null,
      },
    };
  },
  computed: {
    massA() {
      return this.taskState.getValueBySymbol('massA').content;
    },
    pctA() {
      return this.taskState.getValueBySymbol('pctA').content;
    },
  },
};
</script>
